<template>
  <Field :label="name">
    <div class="vp-flex vp-items-center vp-mt-1">
      <div
        class="vp-w-10 vp-h-10 vp-rounded-md vp-relative vp-cursor-pointer vp-flex vp-justify-center vp-items-center vp-text-white"
        :style="{ backgroundColor: shade500 }"
      >
        <input
          class="vp-absolute vp-inset-0 vp-w-full vp-h-full vp-opacity-0 vp-cursor-pointer"
          type="color"
          v-model="shade500"
        />
      </div>
      <div
        class="vp-grid-cols-[repeat(10,28px)] vp-grid vp-rounded-md vp-overflow-hidden vp-ml-auto vp-h-10"
      >
        <div
          v-for="shade in shortedShades"
          :style="{ backgroundColor: palette[shade] }"
          :key="shade"
        ></div>
      </div>
    </div>
  </Field>
</template>

<script>
import Color from "color";
import Field from "./form/field.vue";

export default {
  components: {
    Field,
  },

  props: {
    name: String,
    value: Object,
  },

  data() {
    return {
      color: null,
    };
  },

  computed: {
    shortedShades() {
      return Object.keys(this.value).sort((a, b) => {
        return a - b;
      });
    },

    shade500: {
      get() {
        return this.color || this.value["500"];
      },
      set(value) {
        this.color = value;
        this.$emit("input", this.palette);
      },
    },

    palette() {
      if (!this.color) return this.value;

      const shades = [];
      const master = this.shade500;
      const color = Color(master);
      const hsl = color.hsl();
      const h = hsl.color[0];
      const s = hsl.color[1];
      const l = hsl.color[2];
      const lighterStep = (100 - l) / 5 - 1;
      const darkerStep = l / 4 - 6;

      //Light
      for (var i = 5; i > 0; i--) {
        const lightL = l + i * lighterStep;
        let lightColor = Color.hsl(h, s, lightL);
        shades.push(lightColor.hex());
      }

      //Default
      const defaultColor = Color.hsl(h, s, l);
      shades.push(defaultColor.hex());

      //Dark
      for (var j = 1; j <= 4; j++) {
        const darkL = l - j * darkerStep;
        let darkColor = Color.hsl(h, s, darkL);
        shades.push(darkColor.hex());
      }

      const palette = {};
      shades.forEach((color, index) => {
        if (index == 0) {
          palette["50"] = color;
        } else {
          palette[`${index}00`] = color;
        }
      });

      return palette;
    },
  },
};
</script>
