<template>
  <VyIcon :name="name" v-bind="$attrs" class="vp-shrink-0" />
</template>
<script>
export default {
  props: {
    name: {
      required: true,
    },
  },
};
</script>
